import { Component, OnInit } from '@angular/core';
import { BladeConfig } from 'src/app/core/blade/blade-config';
import { BladeService } from 'src/app/core/blade/blade.service';
import { BladeRef } from 'src/app/core/blade/blade-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from 'src/app/shared/translation/translation.service';
import { LoaderService } from 'src/app/core/loader/loader.service';
import { TemplateDetail } from 'src/app/templates/shared/template-detail.model';
import { TemplateInGrid } from '../shared/template-in-grid.model';
import { TemplateService } from '../shared/template.service';

@Component({
    selector: 'app-templates-detail',
    templateUrl: './templates-detail.component.html',
    styleUrls: ['./templates-detail.component.scss']
})
export class TemplatesDetailComponent implements OnInit {

    constructor(
        private config: BladeConfig,
        private blade: BladeService,
        private bladeRef: BladeRef,
        private modalService: NgbModal,
        private service: TemplateService,
        private t: TranslationService,
        private loaderService: LoaderService,
    ) { }

    loader = this.loaderService.loading;

    template: TemplateDetail = new TemplateDetail();

    ngOnInit() {
        this.loadTemplateDetail();
    }

    close() {
        this.bladeRef.dismiss();
    }

    loadTemplateDetail() {
        const data: TemplateInGrid = this.config.data;

        // Format: DocumentAbonnement_Bewonerskaart_Maxi_Knokke-Heist.nl-BE.rpt
        const templateName = data.Name.split('.');

        this.service.getReminderDetail(data.DepartmentId, templateName[0], templateName[1], templateName[2]).subscribe(
            (t: TemplateDetail) => {
                this.template = t;
                this.loader = this.loaderService.empty;
            });
    }

}
