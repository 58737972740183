import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { Observable, Observer, observable } from 'rxjs';
import { UserService } from '../user/user.service';


@Injectable({
    providedIn: 'root'
})

export class HttpService {


    constructor(
        private http: HttpClient,
        private userService: UserService,
        private environmentService: EnvironmentService,
    ) { }

    public getManApi<D>(path: string) {
        return this.http.get<D>(this.environmentService.manApiUrl + path);
    }

    public getComplaintApi<D>(departmentId: string, path: string) {
        return this.http.get<D>(`${this.environmentService.complaintApiUrl + this.environmentService.complaintApiVersion}/${path}`, this.addDepartmentAndLanguageHeader(departmentId));
    }

    public postComplaintApi<D>(departmentId: string, path: string, body: Object) {
        return this.http.post<D>(`${this.environmentService.complaintApiUrl + this.environmentService.complaintApiVersion}/${path}`, body, this.addDepartmentAndLanguageHeader(departmentId));
    }

    public putComplaintApi<D>(departmentId: string, path: string, body: Object) {
        return this.http.put<D>(`${this.environmentService.complaintApiUrl + this.environmentService.complaintApiVersion}/${path}`, body, this.addDepartmentAndLanguageHeader(departmentId));
    }

    public getSfpsApi<D>(path: string) {
        return this.http.get<D>(this.environmentService.serveurFpsApiUrl + path);
    }

    public getReminderApi<D>(departmentId: string, path: string) {
        return this.http.get<D>(`${this.environmentService.reminderApiUrl + departmentId + '/' + this.environmentService.reminderApiVersion}/${path}`, this.addDepartmentAndLanguageHeader(departmentId));
    }

    public getReminderApiWithLanguage<D>(departmentId: string, path: string, languageCulture: string) {
        return this.http.get<D>(`${this.environmentService.reminderApiUrl + departmentId + '/' + this.environmentService.reminderApiVersion}/${path}`, this.addDepartmentAndSpecifiedLanguageHeader(departmentId, languageCulture));
    }

    public getDocApi<D>(path: string) {
        return new Observable((observer: Observer<D>) => {
            this.http.get<D>(this.environmentService.docApiUrl + path).subscribe(
                (result) => {
                    observer.next(this.toCamel(result));
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getStaticDataApi<D>(departmentsId: string, type: string): Observable<any> {
        const sendObject = {
            departmentIds: [departmentsId],
            dataValueTypeIds: [type],
        };
        return new Observable((observer: Observer<D>) => {
            this.http.post(`${this.environmentService.staticDataApiUrl}staticdata-search/${this.environmentService.staticDataApiVersion}`, sendObject).subscribe(
                (result) => {
                    observer.next(this.toCamel(result));
                    observer.complete();
                },
                (error) => {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getTranslationApi() {
        return this.http.get(`${this.environmentService.translationApiUrl}${this.environmentService.translationApiVersion}/translations/${this.environmentService.applicationId}`, this.addLanguageHeader());
    }

    private addDepartmentAndLanguageHeader(departmentId) {
        return {
            headers: new HttpHeaders({
                departmentId,
                'Accept-Language': this.userService.getLanguageCode(),
            })
        };
    }

    private addDepartmentAndSpecifiedLanguageHeader(departmentId, cultureLanguage) {
        return {
            headers: new HttpHeaders({
                departmentId,
                'Accept-Language': cultureLanguage,
            })
        };
    }

    private addDepartmentHeader(departmentId: string) {
        return {
            headers: new HttpHeaders({
                departmentId
            })
        };
    }

    private addLanguageHeader() {
        return {
            headers: new HttpHeaders({
                'Accept-Language': this.userService.getLanguageCode()
            })
        };
    }

    private toCamel(o) {
        let newO;
        let origKey;
        let newKey;
        let value;
        if (o instanceof Array) {
            return o.map((value) => {
                let v = value;
                if (typeof v === 'object') {
                    v = this.toCamel(v);
                }
                return v;
            });
        }

        newO = {};
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                value = o[origKey];
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = this.toCamel(value);
                }
                newO[newKey] = value;
            }
        }

        return newO;
    }
}
